import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router';
import { ROUTE_LOGIN } from '../../utils/constants';
import useHasPMTAccess from '../../hooks/useHasPMTAccess';

interface SecureRouteProps {
  children: React.ReactElement;
}

type Props = SecureRouteProps;

const SecureRoute = ({ children }: Props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const hasAccess = useHasPMTAccess();
  const navigate = useNavigate();

  if (!authState?.isAuthenticated || !hasAccess) {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);

    navigate(ROUTE_LOGIN);

    return null;
  }

  return children;
};

export default SecureRoute;
