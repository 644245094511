import { gql } from '@apollo/client';
import { EmailList } from 'server/src/graphql/resolvers/RoleEmailList/types';
import { emailListFragment } from '../../fragments/emailList';

export interface ResultsGetEmailLists {
  getEmailLists: EmailList[];
}

export const GET_EMAIL_LISTS_QUERY = gql`
  query getEmails {
    getEmailLists {
      ...EmailListTemplate
    }
  }
  ${emailListFragment}
`;
