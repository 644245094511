import React from 'react';
import { Link } from 'react-router-dom';

export interface Crumb {
  name: string;
  path: string;
}

function Breadcrumbs({ options }: { options: Crumb[] }) {
  const listItems = options.map((item, index) => (
    <li key={`Breadcrumb-${index}`}>
      <Link to={item.path} reloadDocument={true}>
        {item.name}
      </Link>
    </li>
  ));

  return (
    <div className='py-4 bg-base-200'>
      <div className='content-container'>
        <div className='text-sm breadcrumbs'>
          <ul>{listItems}</ul>
        </div>
      </div>
    </div>
  );
}
export default Breadcrumbs;
