import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router';
import { LoginCallback } from '@okta/okta-react';
import {
  ADD_NEW_OPS_GUIDE_ROUTE,
  ADD_NEW_PROMOTION_ROUTE,
  ANALYSIS_ROUTE,
  CALENDAR_ROUTE,
  COPY_EXISTING_PROMOTION_ROUTE,
  DASHBOARD_ROUTE,
  DOWNLOAD_PDF_ROUTE,
  EMAIL_LIST_ROUTE,
  FORM_APPROVAL_ROUTE,
  OPS_GUIDE_ROUTE,
  PRIZE_CODE_REQUEST_ROUTE,
  ROUTE_LOGIN,
  ROUTE_OKTA_LOGIN_CALLBACK,
} from './utils/constants';
import SecureRoute from './components/SecureRoute/SecureRoute';
import EmailList from './routes/Email/EmailList';

const DashboardPage = lazy(() => import('./routes/Dashboard/Dashboard'));
const CalendarPage = lazy(() => import('./routes/Calendar/Calendar'));
const LoginPage = lazy(() => import('./routes/Login/Login'));
const ApprovalPage = lazy(() => import('./routes/Approval/Approval'));
const OPSGuide = lazy(() => import('./routes/OpsGuide/OpsGuide'));
const CreateOpsGuidePage = lazy(() => import('./routes/OpsGuide/CreateOpsGuide'));
const ApprovalOpsGuidePage = lazy(() => import('./routes/OpsGuide/ApprovalOpsGuide'));
const AddNewPromotion = lazy(() => import('./routes/Promotion/AddPromotion'));
const CopyExistingPromotion = lazy(() => import('./routes/Promotion/CopyPromotion'));
const EditPromotion = lazy(() => import('./routes/Promotion/EditPromotion'));
const NewPromotionForm = lazy(() => import('./routes/Promotion/NewPromotionForm'));
const ApprovalPromoPage = lazy(() => import('./routes/Promotion/ApprovalPromotion'));
const AnalysisPage = lazy(() => import('./routes/Analysis/Analysis'));
const DownloadPdfPage = lazy(() => import('./routes/Download/DownloadPdf'));
const PrizeCodeRequestPage = lazy(() => import('./routes/PrizeCodeRequest/PrizeCodeRequest'));

function Routes() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ReactRouterRoutes>
        <Route path={ROUTE_OKTA_LOGIN_CALLBACK} element={<LoginCallback />} />
        <Route path={ROUTE_LOGIN} element={<LoginPage />} />
        <Route
          path={DASHBOARD_ROUTE}
          element={
            <SecureRoute>
              <DashboardPage />
            </SecureRoute>
          }
        />
        <Route
          path={CALENDAR_ROUTE}
          element={
            <SecureRoute>
              <CalendarPage />
            </SecureRoute>
          }
        />
        <Route
          path={`${OPS_GUIDE_ROUTE}/:opsId`}
          element={
            <SecureRoute>
              <OPSGuide />
            </SecureRoute>
          }
        />
        <Route
          path={ADD_NEW_OPS_GUIDE_ROUTE}
          element={
            <SecureRoute>
              <CreateOpsGuidePage />
            </SecureRoute>
          }
        />
        <Route
          path={`${ADD_NEW_PROMOTION_ROUTE}/:opsId`}
          element={
            <SecureRoute>
              <AddNewPromotion />
            </SecureRoute>
          }
        />
        <Route
          path={`${COPY_EXISTING_PROMOTION_ROUTE}/:opsId`}
          element={
            <SecureRoute>
              <CopyExistingPromotion />
            </SecureRoute>
          }
        />
        <Route
          path={`${OPS_GUIDE_ROUTE}/:opsId/promos/:promoId/new`}
          element={
            <SecureRoute>
              <NewPromotionForm />
            </SecureRoute>
          }
        />
        <Route
          path={FORM_APPROVAL_ROUTE}
          element={
            <SecureRoute>
              <ApprovalPage />
            </SecureRoute>
          }
        />
        <Route
          path={`${FORM_APPROVAL_ROUTE}/:opsId/`}
          element={
            <SecureRoute>
              <ApprovalOpsGuidePage />
            </SecureRoute>
          }
        />
        <Route
          path={`${FORM_APPROVAL_ROUTE}/:opsId/promo/:promoId`}
          element={
            <SecureRoute>
              <ApprovalPromoPage />
            </SecureRoute>
          }
        />
        <Route path={`${OPS_GUIDE_ROUTE}/:opsId/promos/:promoId`}>
          <Route
            index
            element={
              <SecureRoute>
                <EditPromotion />
              </SecureRoute>
            }
          />
          <Route
            path={':tab'}
            element={
              <SecureRoute>
                <EditPromotion />
              </SecureRoute>
            }
          />
        </Route>
        <Route
          path={EMAIL_LIST_ROUTE}
          element={
            <SecureRoute>
              <EmailList />
            </SecureRoute>
          }
        />
        <Route
          path={ANALYSIS_ROUTE}
          element={
            <SecureRoute>
              <AnalysisPage />
            </SecureRoute>
          }
        />
        <Route
          path={`${DOWNLOAD_PDF_ROUTE}/:formType/:formId`}
          element={
            <SecureRoute>
              <DownloadPdfPage />
            </SecureRoute>
          }
        />
        <Route
          path={PRIZE_CODE_REQUEST_ROUTE}
          element={
            <SecureRoute>
              <PrizeCodeRequestPage />
            </SecureRoute>
          }
        />
        <Route path='*' element={<Navigate replace to={DASHBOARD_ROUTE} />} />
      </ReactRouterRoutes>
    </Suspense>
  );
}

export default Routes;
