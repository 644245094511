import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { GET_EMAIL_LISTS_QUERY, ResultsGetEmailLists } from 'graphql/queries/EmailList/getEmailLists';
import { ResultsUpdateEmailList, UPDATE_EMAIL_LIST_MUTATION } from 'graphql/mutations/EmailList/updateEmailList';
import Layout from 'components/Layout/Layout';
import MainNavigation from 'components/MainNavigation/MainNavigation';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import EmailListItem from 'components/EmailList/EmailListItem';
import Chevron from '../../components/Chevron/Chevron';
import 'react-toastify/dist/ReactToastify.css';
import { EMAIL_ROLES_LABELS, PROPERTY_CODE_AS_PROPERTY } from 'utils/constants';
import { routeToDashboard, routeToEmailList } from 'utils/navigation';

const setToast = (msg: string, success?: boolean) => {
  if (success) {
    toast.success(msg, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  } else {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }
};

function EmailList() {
  const { data: emails, refetch: getEmailLists } = useQuery<ResultsGetEmailLists>(GET_EMAIL_LISTS_QUERY);
  const [updateEmailList] = useMutation<ResultsUpdateEmailList>(UPDATE_EMAIL_LIST_MUTATION);
  const [selectedList, setSelectedList] = useState('');

  const removeEmail = async (email: string, role: string, listId: string) => {
    const emailList = emails?.getEmailLists.find((x) => x.id === listId);

    if (emailList && emailList[role]) {
      if (emailList[role].includes(email)) {
        await updateEmailList({
          variables: {
            updateValues: {
              role: role,
              emails: emailList[role].filter((x) => x !== email) || [],
              emailListId: listId,
            },
          },
        });
      }

      getEmailLists();
    }
  };

  const addNewEmail = (role: string, listId: string, newEmail: string) => {
    const emailList = emails?.getEmailLists.find((x) => x.id === listId);
    if (emailList && emailList[role] && !emailList[role].includes(newEmail)) {
      updateEmailList({
        variables: {
          updateValues: {
            role: role,
            emails: [...(emailList[role] || []), newEmail],
            emailListId: listId,
          },
        },
      }).then((data) => {
        // Expect one message.
        data.data?.updateEmailList.errors?.forEach((x) => {
          setToast(x.msg, data.data?.updateEmailList.success);
        });
        getEmailLists();
      });
    }
  };

  return (
    <Layout title='Email List'>
      <div className=''>
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
        <MainNavigation hideNav={false} />
        <Breadcrumbs
          options={[
            { name: 'Dashboard', path: routeToDashboard() },
            { name: 'Email List', path: routeToEmailList() },
          ]}
        />
        <div className='banner-base py-4 bg-base-200'>
          <div className='content-container'>
            <h4 className='text-4xl leading-8 text-primary mt-4 mb-4 knockout-32'>Email List</h4>
          </div>
        </div>
        <div className='content-container mb-20 mt-8'>
          {emails?.getEmailLists?.map((emailList) => {
            return (
              <Accordion
                key={emailList.id}
                className='w-full text-left border-t border-gray-300 items-center'
                TransitionProps={{ unmountOnExit: true }}
                onChange={() => setSelectedList(emailList.id)}
                sx={{
                  boxShadow: 0,
                  backgroundColor: 'transparent',
                  '&.MuiAccordion-root.Mui-expanded': {
                    marginBottom: '0',
                  },
                }}
              >
                <div
                  className={`w-full bold ${
                    selectedList === emailList.id ? 'approval-active' : 'hover:bg-secondary-100/20'
                  }`}
                >
                  <AccordionSummary
                    className='flex w-full'
                    expandIcon={<Chevron selected={selectedList === emailList.id} />}
                    sx={{
                      '& > .MuiAccordionSummary-content.Mui-expanded': {
                        margin: '0.75rem 0 0.75rem',
                      },
                    }}
                  >
                    <h2
                      className={`text-3xl p-2 font-bold ${
                        selectedList === emailList.id ? 'text-white' : 'text-primary-500'
                      }`}
                    >
                      {PROPERTY_CODE_AS_PROPERTY[emailList.property]}
                    </h2>
                  </AccordionSummary>
                </div>
                {Object.keys(emailList).map((role) => {
                  if (Object.keys(EMAIL_ROLES_LABELS).includes(role)) {
                    return (
                      <AccordionDetails
                        key={`${PROPERTY_CODE_AS_PROPERTY[emailList.property]}${EMAIL_ROLES_LABELS[role]}`}
                        className='w-full bg-white'
                        sx={{
                          padding: '1rem 1rem 0 1rem',
                          '&:last-child': {
                            paddingBottom: '1rem',
                          },
                        }}
                      >
                        <EmailListItem
                          title={role}
                          emails={emailList[role] || []}
                          removeEmail={(email) => removeEmail(email, role, emailList.id)}
                          addEmail={(email) => addNewEmail(role, emailList.id, email)}
                          handleToast={setToast}
                        />
                      </AccordionDetails>
                    );
                  }

                  return null;
                })}
              </Accordion>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}

export default EmailList;
