import React from 'react';

interface StaticImageProps {
  src: string;
  alt: string;
  width?: number | string;
  className?: string;
}

type Props = StaticImageProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const StaticImage = ({ className, src, alt, width, ...props }: Props) => {
  return (
    <div
      className={`gatsby-image-wrapper gatsby-image-wrapper-constrained ${className}`.trim()}
      style={{
        maxWidth: width,
      }}
    >
      <img src={src} alt={alt} {...props} />
    </div>
  );
};

export default StaticImage;
