import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { UserClaims } from '@okta/okta-auth-js';

export default function useUserInfo() {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (authState?.isAuthenticated) {
        setLoading(true);
        // You can also get user information from the `/userinfo` endpoint
        const info = await oktaAuth.getUser();
        setLoading(false);
        setUserInfo(info);
      } else {
        setUserInfo(undefined);
        setLoading(false);
      }
    })();
  }, [authState?.isAuthenticated, oktaAuth]);

  return {
    userInfo,
    loading,
  };
}
