import React from 'react';

interface ChevronProps {
  selected: boolean;
}
type Props = ChevronProps;

function Chevron({ selected }: Props) {
  const textColor = selected ? 'text-white' : 'text-darkgray-800';

  return (
    <div className={`ml-auto ${textColor} transition-transform duration-200 ease-out ${selected && 'rotate-45'}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='currentColor'
        className='w-6 h-6'
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
      </svg>
    </div>
  );
}

export default Chevron;
