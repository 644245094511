import { useOktaAuth } from '@okta/okta-react';
import { useMemo } from 'react';
import { hasPMTAccess } from '../utils/authUtils';

export default function useHasPMTAccess() {
  const { authState } = useOktaAuth();

  return useMemo(
    () => (authState?.isAuthenticated && authState?.accessToken ? hasPMTAccess(authState.accessToken.claims) : false),
    [authState?.accessToken, authState?.isAuthenticated]
  );
}
