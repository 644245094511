import React, { useCallback, useMemo } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Logo from '../../images/logo.png';
import PmtLogo from '../../images/pmt-logo.png';
import ArrowDownImg from '../../images/arrow-down.png';
import {
  ANALYSIS_ROUTE,
  CALENDAR_ROUTE,
  DASHBOARD_ROUTE,
  PRIZE_CODE_REQUEST_ROUTE,
  EMAIL_LIST_ROUTE,
  FORM_APPROVAL_ROUTE,
} from '../../utils/constants';
import StaticImage from '../StaticImage/StaticImage';
import { useOktaAuth } from '@okta/okta-react';
import useUserInfo from '../../hooks/useUserInfo';
import { IDToken, UserClaims } from '@okta/okta-auth-js';
import env from '../../config/env';
import { Box } from '@mui/material';

function getUserInitials(idToken?: IDToken, userInfo?: UserClaims) {
  if (!idToken && !userInfo) {
    return undefined;
  }

  // If userInfo exists, use first/last names from that.
  let firstName: string | undefined;
  let lastName: string | undefined;
  const name: string | undefined = userInfo?.name || idToken?.claims.name;

  if (userInfo) {
    firstName = userInfo.given_name;
    lastName = userInfo.family_name;
  }

  if (!firstName && !lastName && !name) {
    return undefined;
  }

  if (!firstName && name) {
    firstName = name.split(' ')[0];
  }

  if (!lastName && name) {
    const nameParts = name.split(' ');
    if (nameParts.length > 1) {
      lastName = nameParts[nameParts.length - 1];
    }
  }

  const initials = `${firstName ? firstName.charAt(0) : ''}${lastName ? lastName.charAt(0) : ''}`;

  return initials.length > 0 ? initials : undefined;
}

interface MainNavigationProps {
  hideNav?: boolean;
}

type Props = MainNavigationProps;

function MainNavigation({ hideNav }: Props) {
  const { oktaAuth, authState } = useOktaAuth();
  const { userInfo } = useUserInfo();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = useCallback(async () => {
    await oktaAuth.signOut();
  }, [oktaAuth]);

  // could be http:// or https:// depending on environment.
  const firstSlash = window.location.href.indexOf('/');

  // + 2 skips to the character immediately following http:// or https://
  const routeLeadingSlash = window.location.href.indexOf('/', firstSlash + 2);

  // i.e. /email-list, /calendar, etc.
  const currentRoute = window.location.href.substring(routeLeadingSlash);

  // Getting User Initials for top nav image
  const userInitials = useMemo(() => getUserInitials(authState?.idToken, userInfo), [authState?.idToken, userInfo]);

  return (
    <div className='relative bg-base-100 drop-shadow-lg' id='start'>
      <div className='header-container content-container'>
        <div className='w-1/2 pt-4 text-center md:w-full'>
          <a href='/'>
            <img
              src={Logo}
              alt='Wind Creek Logo'
              className='max-w-full mx-auto'
              placeholder='blurred'
              width={243}
              height={48}
            />
          </a>
        </div>
        <div className='flex-col w-full pb-6 text-base navbar md:flex-row md:px-0 '>
          <div className='flex-1'>
            <div id='logo'>
              <img
                src={PmtLogo}
                alt='Promotion Management Tool'
                className='max-w-full mx-auto'
                placeholder='blurred'
                width={150}
              />
            </div>
          </div>
          <div className={`pt-3 md:pt-0 flex-none ${hideNav && ' hidden'}`}>
            <ul className='items-stretch px-3 menu horizontal'>
              <li
                className={`mx-2 sm:mx-4 border-b-2 border-base-300 ${
                  currentRoute === PRIZE_CODE_REQUEST_ROUTE && 'border-neutral'
                }`}
              >
                <a
                  href={PRIZE_CODE_REQUEST_ROUTE}
                  className='px-0 text-sm tracking-wider lg:text-lg knockout text-primary'
                >
                  Prize Code Request
                </a>
              </li>
              <li
                className={`mx-2 sm:mx-4 border-b-2 border-base-300 ${
                  currentRoute === DASHBOARD_ROUTE && 'border-neutral'
                }`}
              >
                <a href={DASHBOARD_ROUTE} className='px-0 text-sm tracking-wider lg:text-lg knockout text-primary'>
                  Dashboard
                </a>
              </li>
              <li
                className={`mx-2 sm:mx-4 border-b-2 border-base-300 ${
                  currentRoute === CALENDAR_ROUTE && 'border-neutral'
                }`}
              >
                <a href={CALENDAR_ROUTE} className='px-0 text-sm tracking-wider lg:text-lg knockout text-primary'>
                  Calendar
                </a>
              </li>
              <li
                className={`mx-2 sm:mx-4 border-b-2 border-base-300 ${
                  currentRoute === FORM_APPROVAL_ROUTE && 'border-neutral'
                }`}
              >
                <a href={FORM_APPROVAL_ROUTE} className='px-0 text-sm tracking-wider lg:text-lg knockout text-primary'>
                  Active Projects
                </a>
              </li>
              <li
                className={`mx-2 sm:mx-4 border-b-2 border-base-300 ${
                  currentRoute === EMAIL_LIST_ROUTE && 'border-neutral'
                }`}
              >
                <a href={EMAIL_LIST_ROUTE} className='px-0 text-sm tracking-wider lg:text-lg knockout text-primary'>
                  Email List
                </a>
              </li>
              <li
                className={`mx-2 sm:mx-4 border-b-2 border-base-300 ${
                  currentRoute === ANALYSIS_ROUTE && 'border-neutral'
                }`}
              >
                <a href={ANALYSIS_ROUTE} className='px-0 text-sm tracking-wider lg:text-lg knockout text-primary'>
                  Analysis
                </a>
              </li>
            </ul>
          </div>

          {!hideNav && (
            <Button
              className={`flex items-end flex-nowrap`}
              onClick={handleClick}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <Box
                className='flex items-center justify-center rounded-full bg-secondary'
                sx={{ padding: '0.25rem', maxWidth: '2.5rem', maxHeight: '2.5rem' }}
              >
                <p className='text-sm tracking-wider text-white'>{userInitials || 'U'}</p>
              </Box>
              <div className='w-6 ml-3 mt-3'>
                <StaticImage src={ArrowDownImg} alt='Profile' placeholder='blurred' width={12} />
              </div>
            </Button>
          )}
          <Menu
            id='basic-menu'
            elevation={3}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              marginTop: '1rem',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              className: 'mt-40 rounded shadow menu bg-base-100 w-36',
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
                li: {
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                },
                'li a': {
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '0.25rem',
                },
                'li:hover': {
                  backgroundColor: 'transparent',
                },
              },
            }}
            className={`dropdown dropdown-end absolute mx-2 top-4 right-4 md:relative md:top-auto md:right-auto ${
              hideNav && ' hidden'
            }`}
          >
            <MenuItem onClick={handleClose}>
              <a
                href={`https://${env.okta.domain}/enduser/settings`}
                target='_blank'
                className='text-sm'
                rel='noreferrer'
              >
                Profile
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a href='#no' onClick={handleSignOut} className='text-sm'>
                Sign Out
              </a>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default MainNavigation;
