import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { EMAIL_ROLES_LABELS } from 'utils/constants';
import EmailValidator from 'email-validator';

interface EmailListItemProps {
  emails: string[];
  title?: string;
  removeEmail?: (email: string) => void;
  addEmail?: (email: string) => void;
  handleToast?: (message: string, success: boolean) => void;
}
type Props = EmailListItemProps;

function EmailListItem({ addEmail, removeEmail, emails, title, handleToast }: Props) {
  const [emailList, setEmailList] = useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (emails) => {
    // We either add or remove depending on how the length changed.
    if (emails.length > emailList.length) {
      const addedEmails = emails.filter((x) => !emailList.includes(x));
      if (addedEmails.map((x) => EmailValidator.validate(x)).includes(false)) {
        handleToast && handleToast('Invalid email address, please update.', false);
        return;
      }

      if (addEmail && title && inputValue) {
        addEmail(inputValue);
      }
    } else if (emailList.length > emails.length) {
      const emailToRemove = emailList.filter((x) => !emails.includes(x));
      if (removeEmail && emailToRemove.length > 0) {
        removeEmail(emailToRemove[0]);
      }
    }

    setEmailList(emails);
  };

  useEffect(() => {
    if (emailList.length === 0 && emails.length > 0) {
      setEmailList(emails);
    }
  }, [emailList, emails, setEmailList]);

  return (
    emails && (
      <div>
        {title && <label className='whitespace-nowrap'>{EMAIL_ROLES_LABELS[title]}</label>}
        <Autocomplete
          defaultValue={[]}
          renderInput={(params) => <TextField {...params} sx={{ background: 'white' }} />}
          onChange={(event, values) => handleChange(values)}
          value={emailList}
          options={[]}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip key={key} variant='outlined' label={option} {...tagProps} />;
            });
          }}
          multiple
          freeSolo
          sx={{
            flexGrow: 1,
          }}
        />
      </div>
    )
  );
}

export default EmailListItem;
