import { UserClaims } from '@okta/okta-auth-js';
import { AccessToken } from '@okta/okta-auth-js/lib/types/Token';
import {
  APPROVALS_PRIMARY,
  APPROVALS_SECONDARY,
  APPROVED,
  NOT_SUBMITTED,
  SUMMARY,
  UNDER_REVIEW,
} from 'server/src/utils/constants';

// Extend the UserClaims to include any custom claims added.
export type AppUserClaims = UserClaims<{
  groups?: OktaGroup[];
}>;

enum OktaGroup {
  UserAccess = 'PMT-UserAccess',
  AdminAccess = 'PMT-AdminAccess',
  ReviewerAccess = 'PMT-ReviewerAccess',
  PropertyAtmore = 'PMT-PropertyAtmore',
  PropertyBethlehem = 'PMT-PropertyBethlehem',
  PropertyMontgomery = 'PMT-PropertyMontgomery',
  PropertyMiami = 'PMT-PropertyMiami',
  PropertyWetumpka = 'PMT-PropertyWetumpka',
  PropertyCorporate = 'PMT-PropertyCorporate',
  PropertyAruba = 'PMT-PropertyAruba',
  PropertyCuracao = 'PMT-PropertyCuracao',
  PropertyChicagoSouthland = 'PMT-PropertyChicagoSouthland',
}

const PropertyCodeOktaGroups = {
  WCA: OktaGroup.PropertyAtmore,
  WCB: OktaGroup.PropertyBethlehem,
  WCM: OktaGroup.PropertyMontgomery,
  WCW: OktaGroup.PropertyWetumpka,
  CORP: OktaGroup.PropertyCorporate,
  AUR: OktaGroup.PropertyAruba,
  CUR: OktaGroup.PropertyCuracao,
  MCM: OktaGroup.PropertyMiami,
  CSL: OktaGroup.PropertyChicagoSouthland,
};

const MAP_OPS_STATUS_APPROVED = {
  [NOT_SUBMITTED]: UNDER_REVIEW,
  [UNDER_REVIEW]: APPROVED,
};

const MAP_PROMO_STATUS_APPROVED = {
  [NOT_SUBMITTED]: UNDER_REVIEW,
  [UNDER_REVIEW]: APPROVALS_PRIMARY,
  [APPROVALS_PRIMARY]: APPROVED,
};

const MAP_PROMO_SUMMARY_STATUS_APPROVED = {
  [NOT_SUBMITTED]: UNDER_REVIEW,
  [UNDER_REVIEW]: APPROVALS_PRIMARY,
  [APPROVALS_PRIMARY]: APPROVALS_SECONDARY,
  [APPROVALS_SECONDARY]: APPROVED,
};

const groupsClaimName = 'groups_membership';

const groupsPermitted: OktaGroup[] = [OktaGroup.UserAccess, OktaGroup.AdminAccess, OktaGroup.ReviewerAccess];

export function hasPMTAccess(claims: AppUserClaims): boolean {
  if (!claims[groupsClaimName] || !Array.isArray(claims[groupsClaimName])) {
    return false;
  }

  return !!groupsPermitted.find((group) => claims[groupsClaimName]?.includes(group));
}

export function hasPropertyAccess(accessToken: AccessToken, propertyCode: string): boolean {
  const claims = accessToken.claims;

  if (isPMTAdmin(claims) || isPMTReviewer(claims)) {
    return true;
  }

  if (isAppUserClaims(claims)) {
    const groupName = PropertyCodeOktaGroups[propertyCode];

    return groupName ? hasGroup(claims, groupName) : false;
  }

  return false;
}

export function hasWriteAccess(accessToken: AccessToken): boolean {
  const claims = accessToken.claims;
  return isPMTAdmin(claims) || !isPMTReviewer(claims);
}

function isPMTAdmin(claims: AppUserClaims) {
  return hasGroup(claims, OktaGroup.AdminAccess);
}

function isPMTReviewer(claims: AppUserClaims) {
  return hasGroup(claims, OktaGroup.ReviewerAccess);
}

function hasGroup(claims: AppUserClaims, group: OktaGroup) {
  if (isAppUserClaims(claims)) {
    return !!claims[groupsClaimName]?.includes(group);
  }

  return false;
}

function isAppUserClaims(claims): claims is AppUserClaims {
  return !!claims[groupsClaimName] && Array.isArray(claims[groupsClaimName]);
}

export function getNextApprovalStatus(status: string | undefined, isOps: boolean, promoType?: string): string {
  if (!status) {
    return NOT_SUBMITTED;
  }

  if (isOps) {
    return MAP_OPS_STATUS_APPROVED[status];
  }

  // Promo Approval status is broken up by Summary type (extra approval step)
  return promoType === SUMMARY ? MAP_PROMO_SUMMARY_STATUS_APPROVED[status] : MAP_PROMO_STATUS_APPROVED[status];
}
