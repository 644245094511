import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { oktaAuthClient } from '../../config/oktaAuthConfig';

interface AuthProviderProps {
  children: React.ReactNode;
}

type Props = AuthProviderProps;

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      const toUri = originalUri && !originalUri.includes('/login') ? originalUri : '/';
      navigate(toRelativeUrl(toUri, window.location.origin), { replace: true });
    },
    [navigate]
  );

  return (
    <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default AuthProvider;
