import { gql } from '@apollo/client';
import { SuccessResult } from 'server/src/types/results';
import { emailListFragment } from '../../fragments/emailList';

export interface ResultsUpdateEmailList {
  updateEmailList: SuccessResult;
}

export const UPDATE_EMAIL_LIST_MUTATION = gql`
  mutation UpdateEmailList($updateValues: UpdateRoleEmailsInputs!) {
    updateEmailList(updateValues: $updateValues) {
      success
      ids
      errors {
        msg
      }
      emailLists {
        ...EmailListTemplate
      }
    }
  }
  ${emailListFragment}
`;
