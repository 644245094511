import {
  DASHBOARD_ROUTE,
  OPS_GUIDE_ROUTE,
  ADD_NEW_PROMOTION_ROUTE,
  ADD_NEW_OPS_GUIDE_ROUTE,
  COPY_EXISTING_PROMOTION_ROUTE,
  FORM_APPROVAL_ROUTE,
  EMAIL_LIST_ROUTE,
  ANALYSIS_ROUTE,
  MY_PROJECTS_ROUTE,
} from './constants';

export const routeToDashboard = () => {
  return `${DASHBOARD_ROUTE}`;
};

export const routeToOpsGuidePage = (opsId: string) => {
  return `${OPS_GUIDE_ROUTE}/${opsId}`;
};

export const routeToAddNewOpsGuide = () => {
  return `${ADD_NEW_OPS_GUIDE_ROUTE}`;
};

export const routeToApproval = (opsGuideId: string, promoId?: string) => {
  return `${FORM_APPROVAL_ROUTE}/${opsGuideId}${(promoId && `/promo/${promoId}`) || ''}`;
};

export const routeToPromotionPage = (opsGuideId: string, promoId: string, tab?: string) => {
  return `${routeToOpsGuidePage(opsGuideId)}/promos/${promoId}${tab ? `/${tab}` : ''}`;
};

export const routeToNewPromotionPage = (opsGuideId: string, promoId: string) => {
  return `${routeToPromotionPage(opsGuideId, promoId)}/new`;
};

export const routeToAddNewPromotion = (opsId: string) => {
  return `${ADD_NEW_PROMOTION_ROUTE}/${opsId}`;
};

export const routeToCopyExistingPromotion = (opsId: string) => {
  return `${COPY_EXISTING_PROMOTION_ROUTE}/${opsId}`;
};

export const routeToEmailList = () => {
  return `${EMAIL_LIST_ROUTE}`;
};

export const routeToAnalysis = () => {
  return `${ANALYSIS_ROUTE}`;
};

export const routeToMyProjects = () => {
  return `${MY_PROJECTS_ROUTE}`;
};

export interface DefaultNavigationState {
  refetch: boolean;
}
