import React from 'react';
import {
  ANALYSIS_ROUTE,
  CALENDAR_ROUTE,
  DASHBOARD_ROUTE,
  EMAIL_LIST_ROUTE,
  FORM_APPROVAL_ROUTE,
} from '../../utils/constants';

let showFooterNav = true;
if (typeof window !== 'undefined') {
  if (window.location.href.indexOf('login') > -1) {
    showFooterNav = false;
  }
}

function Footer() {
  return (
    <footer className='py-8 bg-base-200 banner-base'>
      <div className='items-center justify-center p-4 text-base footer content-container md:justify-between gap-y-4'>
        <div className='items-center grid-flow-col uppercase'>
          {showFooterNav && (
            <>
              <a href={DASHBOARD_ROUTE} className='hover:underline'>
                Dashboard
              </a>{' '}
              |
              <a href={CALENDAR_ROUTE} className='hover:underline'>
                Calendar
              </a>{' '}
              |
              <a href={FORM_APPROVAL_ROUTE} className='hover:underline'>
                Active Projects
              </a>
              |
              <a href={EMAIL_LIST_ROUTE} className='hover:underline'>
                Email List
              </a>{' '}
              |
              <a href={ANALYSIS_ROUTE} className='hover:underline'>
                Analysis
              </a>
            </>
          )}
        </div>
        <div className='grid-flow-col gap-4 md:place-self-center md:justify-self-end mt-0-i'>
          © {new Date().getFullYear()} WIND CREEK HOSPITALITY
        </div>
      </div>
    </footer>
  );
}

export default Footer;
