import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useAppApolloClient } from './config/apolloClient';
import Routes from './routes';
import AuthProvider from './components/AuthProvider/AuthProvider';

function App() {
  const apolloClient = useAppApolloClient();

  return (
    <BrowserRouter>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <div className='App'>
            <Routes />
          </div>
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
