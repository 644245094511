import { OktaAuth, TokenManager } from '@okta/okta-auth-js';
import env from './env';
import { ROUTE_OKTA_LOGIN_CALLBACK } from '../utils/constants';

export const oktaAuthClient = new OktaAuth({
  issuer: env.okta.issuer,
  clientId: env.okta.clientId,
  redirectUri: `${window.location.origin}${ROUTE_OKTA_LOGIN_CALLBACK}`,
  scopes: ['openid', 'email', 'profile', 'openid_groups'],
});

export const tokenManager: TokenManager = oktaAuthClient.tokenManager;
