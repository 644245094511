// Statuses for Ops Guides and Promotions
export const NOT_SUBMITTED = 'Not Submitted';
export const IN_PROGRESS = 'In Progress';
export const APPROVALS = 'Approvals';
export const APPROVALS_PRIMARY = 'Approvals (1)';
export const APPROVALS_SECONDARY = 'Approvals (2)';
export const UNDER_REVIEW = 'Under Review';
export const APPROVED = 'Approved';
export const APPROVED_SUMMARY = 'Approved Summary';
export const DENIED = 'Denied';
export const TCG_APPROVED = 'TCG Approved';

// Promotion Formats
export const COME_AND_GET = 'Come and Get';
export const EARN_AND_GET = 'Earn and Get';
export const EBS = 'EBS';
export const ELECTRONIC_DRAWING = 'Electronic Drawing';
export const ELECTRONIC_KIOSK_GAME = 'Electronic Kiosk Game';
export const EVENT = 'Event';
export const HOT_SEAT = 'Hot Seat';
export const OFFER = 'Offer';
export const SUMMARY = 'Summary';
export const SWEEPSTAKES = 'Sweepstakes';
export const TICKET_DRAWING = 'Ticketed Drawing';
export const TOURNAMENT = 'Tournament';
export const ALL_PROMOTION_FORMATS = [
  COME_AND_GET,
  EARN_AND_GET,
  EBS,
  ELECTRONIC_DRAWING,
  ELECTRONIC_KIOSK_GAME,
  EVENT,
  HOT_SEAT,
  OFFER,
  SUMMARY,
  SWEEPSTAKES,
  TICKET_DRAWING,
  TOURNAMENT,
];

// Property codes
export const PROPERTY_ATMORE = 'WCA';
export const PROPERTY_BETHLEHEM = 'WCB';
export const PROPERTY_MONTGOMERY = 'WCM';
export const PROPERTY_WETUMPKA = 'WCW';
export const PROPERTY_CURACAO = 'CUR';
export const PROPERTY_ARUBA = 'AUR';
export const PROPERTY_CORPORATE = 'CORP';
export const PROPERTY_MIAMI = 'MCM';
export const PROPERTY_CHICAGO_SOUTHLAND = 'CSL';
export const PROPERTY_ATMORE_VIP = 'WCA-VIP';
export const PROPERTY_MONTGOMERY_VIP = 'WCM-VIP';
export const PROPERTY_WETUMPKA_VIP = 'WCW-VIP';

export const PROPERTY_TIMEZONES = {
  [PROPERTY_ATMORE]: 'America/Chicago',
  [PROPERTY_BETHLEHEM]: 'America/New_York',
  [PROPERTY_MONTGOMERY]: 'America/Chicago',
  [PROPERTY_WETUMPKA]: 'America/Chicago',
  [PROPERTY_CORPORATE]: 'America/Chicago',
  [PROPERTY_CURACAO]: 'America/Curacao',
  [PROPERTY_ARUBA]: 'America/Aruba',
  [PROPERTY_MIAMI]: 'America/New_York',
  [PROPERTY_CHICAGO_SOUTHLAND]: 'America/Chicago',
  [PROPERTY_ATMORE_VIP]: 'America/Chicago',
  [PROPERTY_MONTGOMERY_VIP]: 'America/Chicago',
  [PROPERTY_WETUMPKA_VIP]: 'America/Chicago',
};

// Inline field types
export const INLINE = 'inline';
export const TEXT = 'text';
export const DATE_PICKER = 'date picker';
export const DATE = 'date';
export const TIME = 'time';
export const TIME_PICKER = 'time picker';
export const HOURLY_TIME_PICKER = 'hourly time picker';
export const NUMBER_SELECTOR_1_60 = '1-60 number selector';
export const NUMBER = 'number';
export const TEXT_WIDE = ' text ';

// Model Names
export const OPS_GUIDE_MODEL = 'OpsGuide';
export const PROMOTION_TEMPLATE_VALUES_MODEL = 'PromotionTemplateValues';
export const PROMOTION_INTERNAL_VALUES_MODEL = 'PromotionInternalValues';
export const PROMO_TEMPLATE_MODEL = 'PromoTemplate';
export const INTERNAL_TEMPLATE_MODEL = 'InternalTemplate';
export const PROMOTION_MODEL = 'Promotion';
export const PROMOTION_FORMAT_MODEL = 'PromotionFormat';
export const PROPERTY_MODEL = 'Property';
export const MIGRATION_MODEL = 'Migration';
export const TEMPLATE_ITEM_MODEL = 'TemplateItem';
export const ROLE_EMAIL_MODEL = 'RoleEmail';
export const COMMENT_MODEL = 'Comment';
export const VENUE_MODEL = 'Venue';
export const ADVERTISEMENT_TYPE_MODEL = 'AdvertisementType';
export const SPONSORSHIP_TYPE_MODEL = 'SponsorshipType';
export const ADVERTISEMENT_MODEL = 'Advertisement';
export const SPONSORSHIP_MODEL = 'Sponsorship';
export const PRIZE_CODE_REQUEST_MODEL = 'PrizeCodeRequest';

// Occurrence parameters
export const START_DATE = 'START_DATE';
export const END_DATE = 'END_DATE';
export const START_TIME = 'START_TIME';
export const END_TIME = 'END_TIME';
export const EXCLUSIONARY_DATE = 'EXCLUSIONARY_DATE';
export const USE_EXCLUSIONARY_DATE = 'USE_EXCLUSIONARY_DATE';
export const WEEKDAY = 'WEEKDAY';

// Config parameters
export const OPS_APPROVAL_REQUIRED_FOR_CALENDAR = false;

// Email
export const OPS_APPROVED_APPROVER = 'Ops Guide Approved Approver';
export const OPS_APPROVED_SUBMITTER = 'Ops Guide Approved Submitter';
export const OPS_DENIED_SUBMITTER = 'Ops Guide Denied Submitter';
export const PROMO_SUBMITTED_APPROVER = 'Promotion Submitted';
export const PROMO_APPROVED_SUBMITTER = 'Promotion Approved Submitter';
export const PROMO_APPROVED_TCG = 'Promotion Approved TCG';
export const PROMO_DENIED_SUBMITTER = 'Promotion Denied Submitter';
export const DEFAULT_ROUTE_URL = 'https://www.windcreek.com/';

// Review Comments
export const REVISION_HISTORY_TYPE_STATUS = 'status';
export const REVISION_HISTORY_TYPE_COMMENT = 'comment';

// Property Codes
// ARC2-156: Site-wide - Miami and Chicago may be re-added at a later time
export const ALL_PROPERTY_CODES = [
  PROPERTY_ARUBA,
  PROPERTY_ATMORE,
  PROPERTY_BETHLEHEM,
  PROPERTY_MONTGOMERY,
  PROPERTY_CORPORATE,
  PROPERTY_CURACAO,
  PROPERTY_WETUMPKA,
  PROPERTY_ATMORE_VIP,
  PROPERTY_MONTGOMERY_VIP,
  PROPERTY_WETUMPKA_VIP,
  // ChicagoSouthlandCode,
  // MiamiCode,
];
