import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

interface LayoutProps {
  children: ReactNode;
  bodyClass?: string;
  overHeadClass?: string;
  title: string;
}

type Props = LayoutProps;

const Layout = ({ children, bodyClass, overHeadClass, title }: Props) => {
  return (
    <>
      <Helmet title={title}>
        <meta name='referrer' content='origin' />
        <link rel='stylesheet' type='text/css' href='//cloud.typography.com/6614852/7403832/css/fonts.css' />
        <link rel='stylesheet' href='//use.typekit.net/llb7eqt.css' />
        <script src='https://cdn.jsdelivr.net/npm/theme-change@2.0.2/index.js' />
      </Helmet>

      <div className={overHeadClass}>
        <main className={bodyClass}>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
