import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import env from './env';
import { setContext } from '@apollo/client/link/context';
import { AccessToken } from '@okta/okta-auth-js';
import { tokenManager } from './oktaAuthConfig';

const httpLink = createHttpLink({
  uri: `${env.app.gatewayUrl}/graphql`,
  headers: {},
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from okta storage if it exists
  const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;
  const token = accessToken.accessToken;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const useAppApolloClient = () => {
  return new ApolloClient({
    uri: `${env.app.gatewayUrl}/graphql`,
    defaultOptions: {},
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    connectToDevTools: true,
  });
};
