interface Env {
  app: {
    serverPort: number | string;
    clientPort: number | string;
    gatewayUrl: string;
    clientUrl: string;
    opsApprovalRequiredForCalendar: boolean;
  };
  okta: {
    domain: string;
    issuer: string;
    clientId: string;
  };
}

function getEnv(envVar: string, defaultValue?: string): string {
  const value = process.env[envVar];

  if (typeof value === 'undefined') {
    if (typeof defaultValue === 'undefined') {
      throw new Error(`Missing required environment variable ${envVar}`);
    }

    return defaultValue;
  }

  return value;
}

const env: Env = {
  app: {
    serverPort: getEnv('REACT_APP_SERVER_PORT', '8000'),
    clientPort: getEnv('REACT_APP_CLIENT_PORT', '3000'),
    gatewayUrl: getEnv('REACT_APP_GATEWAY_URL', 'http://localhost:8000'),
    clientUrl: getEnv('REACT_APP_CLIENT_URL', 'http://localhost:3000'),
    opsApprovalRequiredForCalendar: false,
  },
  okta: {
    domain: getEnv('REACT_APP_OKTA_DOMAIN'),
    issuer: getEnv('REACT_APP_OKTA_ISSUER'),
    clientId: getEnv('REACT_APP_OKTA_CLIENT_ID'),
  },
};

export default env;
